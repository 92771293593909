var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        ref: "deposit-bulk-table",
        class: {
          bordered: true,
          "mb-1": true,
        },
        attrs: {
          "show-empty": "",
          striped: "",
          small: "",
          responsive: "",
          "tbody-tr-class": _vm.rowClass,
          fields: _vm.fields,
          items: _vm.items,
          "empty-text": _vm.getEmptyTableMessage(
            _vm.$tc("BANK_DEPOSIT.UI.PAGE_XLSX_IMPORT_SAVE.NAME"),
            "male"
          ),
          "current-page": _vm.paging.currentPage,
          "per-page": _vm.paging.pageSize,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "cell(actions)",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c("e-grid-actions", {
                    attrs: {
                      "show-update": false,
                      "show-delete": false,
                      buttons: _vm.gridActions,
                    },
                    on: {
                      "show-details": function ($event) {
                        return _vm.onShowDetails(item)
                      },
                    },
                  }),
                ]
              },
            },
            {
              key: "cell(storeId)",
              fn: function (ref) {
                var item = ref.item
                var index = ref.index
                return [
                  _c("e-store-combo", {
                    attrs: {
                      id: "bulk_table-store_id_" + index,
                      name: "storeId_" + index,
                      "validation-messages": {
                        required: _vm.$t("Obrigatório"),
                      },
                      "show-label": false,
                      "append-to-body": "",
                    },
                    on: {
                      input: function () {
                        return _vm.onBlurField(item)
                      },
                    },
                    model: {
                      value: item.storeId,
                      callback: function ($$v) {
                        _vm.$set(item, "storeId", $$v)
                      },
                      expression: "item.storeId",
                    },
                  }),
                ]
              },
            },
            {
              key: "cell(depositDate)",
              fn: function (ref) {
                var item = ref.item
                var index = ref.index
                return [
                  _c("FormulateInput", {
                    attrs: {
                      id: "bulk_table-deposit_date_" + index,
                      name: "depositDate_" + index,
                      type: "datepicker",
                      placeholder: _vm.$t("dd/MM/aaaa"),
                      validation: "required",
                      "validation-messages": {
                        required: _vm.$t("Obrigatório"),
                      },
                    },
                    on: {
                      input: function () {
                        return _vm.onBlurField(item)
                      },
                    },
                    model: {
                      value: item.depositDate,
                      callback: function ($$v) {
                        _vm.$set(item, "depositDate", $$v)
                      },
                      expression: "item.depositDate",
                    },
                  }),
                ]
              },
            },
            {
              key: "cell(description)",
              fn: function (ref) {
                var item = ref.item
                var index = ref.index
                return [
                  _c("FormulateInput", {
                    attrs: {
                      id: "bulk_table-description_" + index,
                      name: "description_" + index,
                      type: "textarea",
                      validation: "required",
                      "validation-messages": {
                        required: _vm.$t("Obrigatório"),
                      },
                    },
                    on: {
                      blur: function () {
                        return _vm.onBlurField(item)
                      },
                    },
                    model: {
                      value: item.description,
                      callback: function ($$v) {
                        _vm.$set(item, "description", $$v)
                      },
                      expression: "item.description",
                    },
                  }),
                ]
              },
            },
            _vm.isEchopeModel
              ? {
                  key: "cell(documentType)",
                  fn: function (ref) {
                    var item = ref.item
                    var index = ref.index
                    return [
                      _c("FormulateInput", {
                        attrs: {
                          id: "bulk_table-document_type_" + index,
                          name: "documentType_" + index,
                          type: "vue-select",
                          options: _vm.documentTypeOptions(),
                          "validation-messages": {
                            required: _vm.$t("Obrigatório"),
                          },
                          validation: "required",
                        },
                        on: {
                          input: function () {
                            return _vm.onBlurField(item)
                          },
                        },
                        model: {
                          value: item.documentType,
                          callback: function ($$v) {
                            _vm.$set(item, "documentType", $$v)
                          },
                          expression: "item.documentType",
                        },
                      }),
                    ]
                  },
                }
              : null,
            {
              key: "cell(documentNumber)",
              fn: function (ref) {
                var item = ref.item
                var index = ref.index
                return [
                  _c("FormulateInput", {
                    attrs: {
                      id: "bulk_table-document_number_" + index,
                      name: "document_number_" + index,
                      type: "text",
                      "validation-messages": {
                        required: _vm.$t("Obrigatório"),
                      },
                      validation: "required",
                    },
                    on: {
                      blur: function () {
                        return _vm.onBlurField(item)
                      },
                    },
                    model: {
                      value: item.documentNumber,
                      callback: function ($$v) {
                        _vm.$set(item, "documentNumber", $$v)
                      },
                      expression: "item.documentNumber",
                    },
                  }),
                ]
              },
            },
            {
              key: "cell(value)",
              fn: function (ref) {
                var item = ref.item
                var index = ref.index
                return [
                  _c("FormulateInput", {
                    attrs: {
                      id: "bulk_table-value_" + index,
                      name: "value_" + index,
                      type: "text-number",
                      currency: "R$",
                      precision: 2,
                      "validation-messages": {
                        required: _vm.$t("Obrigatório"),
                        min: _vm.$t("Deve ser maior que 0.01"),
                      },
                      validation: "required|min:0.01",
                    },
                    on: {
                      blur: function () {
                        return _vm.onBlurField(item)
                      },
                    },
                    model: {
                      value: item.value,
                      callback: function ($$v) {
                        _vm.$set(item, "value", $$v)
                      },
                      expression: "item.value",
                    },
                  }),
                ]
              },
            },
            {
              key: "row-details",
              fn: function (ref) {
                var item = ref.item
                var index = ref.index
                return [
                  _c(
                    "div",
                    {
                      staticClass: "bg-light p-1 rounded",
                      staticStyle: { "margin-left": "150px" },
                    },
                    [
                      _c("p", { staticClass: "h4" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Dados importados da planilha")) +
                            " "
                        ),
                      ]),
                      _c("bank-deposit-prosegur-xlsx-inputs", {
                        attrs: { item: item, index: index },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "custom-foot",
              fn: function () {
                return [
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "text-right", attrs: { colspan: "6" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("Correção pendente") +
                                ": " +
                                _vm.summary.totalErrors
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v(" Total "),
                    ]),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("currency")(_vm.summary.totalItems)) +
                          " "
                      ),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }