var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-1" },
        [
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "prosegur_xlsx-period-" + _vm.index,
                  label: _vm.$t("Período"),
                  type: "label",
                  errors: _vm.item.xlsxErrors["Period"]
                    ? _vm.item.xlsxErrors["Period"]
                    : [],
                },
                model: {
                  value: _vm.item.xlsxData.period,
                  callback: function ($$v) {
                    _vm.$set(_vm.item.xlsxData, "period", $$v)
                  },
                  expression: "item.xlsxData.period",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "prosegur_xlsx-company_name-" + _vm.index,
                  label: _vm.$t("Razão Social"),
                  type: "label",
                  errors: _vm.item.xlsxErrors["CompanyName"]
                    ? _vm.item.xlsxErrors["CompanyName"]
                    : [],
                },
                model: {
                  value: _vm.item.xlsxData.companyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.item.xlsxData, "companyName", $$v)
                  },
                  expression: "item.xlsxData.companyName",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "prosegur_xlsx-device_id-" + _vm.index,
                  label: _vm.$t("Device Id"),
                  type: "label",
                  errors: _vm.item.xlsxErrors["DeviceId"]
                    ? _vm.item.xlsxErrors["DeviceId"]
                    : [],
                },
                model: {
                  value: _vm.item.xlsxData.deviceId,
                  callback: function ($$v) {
                    _vm.$set(_vm.item.xlsxData, "deviceId", $$v)
                  },
                  expression: "item.xlsxData.deviceId",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "prosegur_xlsx-point-" + _vm.index,
                  label: _vm.$t("Ponto"),
                  type: "label",
                  errors: _vm.item.xlsxErrors["Point"]
                    ? _vm.item.xlsxErrors["Point"]
                    : [],
                },
                model: {
                  value: _vm.item.xlsxData.point,
                  callback: function ($$v) {
                    _vm.$set(_vm.item.xlsxData, "point", $$v)
                  },
                  expression: "item.xlsxData.point",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "prosegur_xlsx-bank_number-" + _vm.index,
                  label: _vm.$t("Banco"),
                  type: "label",
                  errors: _vm.item.xlsxErrors["BankNumber"]
                    ? _vm.item.xlsxErrors["BankNumber"]
                    : [],
                },
                model: {
                  value: _vm.item.xlsxData.bankNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.item.xlsxData, "bankNumber", $$v)
                  },
                  expression: "item.xlsxData.bankNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "prosegur_xlsx-bank_branch_number-" + _vm.index,
                  label: _vm.$t("Agência"),
                  type: "label",
                  errors: _vm.item.xlsxErrors["BankBranchNumber"]
                    ? _vm.item.xlsxErrors["BankBranchNumber"]
                    : [],
                },
                model: {
                  value: _vm.item.xlsxData.bankBranchNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.item.xlsxData, "bankBranchNumber", $$v)
                  },
                  expression: "item.xlsxData.bankBranchNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "prosegur_xlsx-bank_account_number-" + _vm.index,
                  label: _vm.$t("Conta"),
                  type: "label",
                  errors: _vm.item.xlsxErrors["BankAccountNumber"]
                    ? _vm.item.xlsxErrors["BankAccountNumber"]
                    : [],
                },
                model: {
                  value: _vm.item.xlsxData.bankAccountNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.item.xlsxData, "bankAccountNumber", $$v)
                  },
                  expression: "item.xlsxData.bankAccountNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "prosegur_xlsx-value-" + _vm.index,
                  label: _vm.$t("Valor a creditar"),
                  type: "label",
                  filter: "currency",
                  errors: _vm.item.xlsxErrors["Value"]
                    ? _vm.item.xlsxErrors["Value"]
                    : [],
                },
                model: {
                  value: _vm.item.xlsxData.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.item.xlsxData, "value", $$v)
                  },
                  expression: "item.xlsxData.value",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }