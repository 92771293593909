<template>
  <section>
    <b-card-actions
      :show-loading="uploading || saving"
      action-collapse
      :title="$t('Importar Planilha')"
    >
      <FormulateForm
        v-show="!uploading"
        ref="uploadForm"
      >
        <!-- TODO código para quando implementar a importação com modelo da echope -->
        <!-- <b-row>
          <b-col
            md="3"
            class="mb-1"
          >
            <FormulateInput
              id="xlsx-model_imported"
              v-model="importForm.modelImported"
              :type="hasFileImported ? 'label' : 'vue-select'"
              :label="$t('Modelo a ser importado')"
              :placeholder="$t('Selecione')"
              :options="bankDepositImportTypeOptions()"
            />
          </b-col>
          <b-col
            v-if="importForm.modelImported === bankDepositImportModelEnum.ECHOPE"
            md="3"
            class="d-flex align-items-center"
          >
            <e-button
              variant="primary"
              :text="$t('Baixar modelo da planilha')"
              size="sm"
              :busy="loadingExampleDownload"
              @click="onDownloadXlsxExample"
            />
          </b-col>
        </b-row> -->

        <b-row>
          <b-col md="12">
            <FormulateInput
              id="xlsx-file"
              ref="xlsxFile"
              v-model="xlsxImportedFile"
              type="uploader"
              max-files="1"
              class="required"
              :theme="'list'"
              :deletable="true"
              :meta="true"
              :accept="'.xlsx'"
              :label="`${$t('Planilha de depósitos')} (PROSEGUR)`"
              validation="required"
              :help-text="
                $t('Arraste a planilha preechida aqui (ou clique para selecionar o arquivo)')
              "
              :delete-confirm-message="
                $t(
                  'A remoção do arquivo fará com que os registros da tela sejam descartados. Deseja continuar?'
                )
              "
              @input="sendFile"
              @delete="removeFiles"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </b-card-actions>

    <b-card-actions
      v-if="hasFileImported && importForm.modelImported === bankDepositImportModelEnum.PROSEGUR"
      action-collapse
      :title="$t('Dados da Prosegur')"
    >
      <b-row>
        <b-col md="3">
          <FormulateInput
            :id="`prosegur_xlsx_info-code`"
            v-model="importForm.prosegurInfo.code"
            :label="$t('Código de Identificação')"
            type="label"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            :id="`prosegur_xlsx_info-date`"
            v-model="importForm.prosegurInfo.date"
            :label="$t('Data')"
            type="label"
            filter="datetime"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            :id="`prosegur_xlsx_info-phisical_balance`"
            v-model="importForm.prosegurInfo.phisicalBalance"
            :label="$t('Saldo físico')"
            type="label"
            filter="currency"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <e-filters
      v-show="hasFileImported"
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="2">
          <FormulateInput
            id="filters-only_errors"
            v-model="filters.onlyErrors"
            type="switch"
            :label="$t('Apenas com erros?')"
          />
        </b-col>
        <b-col md="4">
          <e-store-combo
            id="filters-store"
            v-model="filters.storeId"
            :required="false"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="filters-value"
            v-model="filters.value"
            name="filtersValue"
            type="text-number"
            :label="$t('Valor')"
            currency="R$"
            :precision="2"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      v-if="!!importForm.modelImported"
      v-show="hasFileImported"
      id="tableCard"
      no-actions
      :show-loading="fetching || saving"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <FormulateForm ref="tableForm">
        <bank-deposit-bulk-table
          :model-import="importForm.modelImported"
          :items="getDeposits"
          :paging="paging"
          :summary="getItemsSummary"
        />
      </FormulateForm>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="accounts-payable-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <e-crud-actions
      v-if="hasFileImported"
      @save="onSaveImport"
      @cancel="onCancelImport"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { BRow, BCol, BPagination } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { checkingAccountDomains, downloader } from '@/mixins'
import EPageSizeSelector from '@/views/components/pagination/EPageSizeSelector.vue'
import EPaginationSummary from '@/views/components/pagination/EPaginationSummary.vue'
import EFilters from '@/views/components/EFilters.vue'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import ECrudActions from '@/views/components/ECrudActions.vue'
import BankDepositBulkTable from './components/BankDepositBulkTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    BCardActions,
    EPageSizeSelector,
    EPaginationSummary,
    EFilters,
    EStoreCombo,
    ECrudActions,
    BankDepositBulkTable,
  },

  mixins: [downloader, checkingAccountDomains],

  data() {
    return {
      saving: false,
      uploading: false,
      loadingExampleDownload: false,
      fetching: false,
      accountPayablesXlsxFile: null,
    }
  },

  computed: {
    ...mapGetters('pages/settings/financial/checkingAccountTransferDepositBulkMaintain', [
      'getDeposits',
      'getItemsSummary',
      'isValidToSave',
    ]),

    ...mapState('pages/settings/financial/checkingAccountTransferDepositBulkMaintain', {
      importForm: 'importForm',
      filters: 'filters',
      paging: 'paging',
      stXlsxImportedFile: 'xlsxImportedFile',
    }),

    xlsxImportedFile: {
      get() {
        return this.stXlsxImportedFile
      },
      set(val) {
        this.stSetXlsxImportedFile(val)
      },
    },

    hasFileImported() {
      return !this.uploading && this.xlsxImportedFile?.length > 0
    },
  },

  // async mounted() {},

  methods: {
    ...mapActions('pages/settings/financial/checkingAccountTransferDepositBulkMaintain', {
      stFilterDeposit: 'filterDeposit',
      stSetXlsxImportedFile: 'setXlsxImportedFile',
      stSendXlsx: 'sendXlsxBankDeposit',
      // stExampleXlsxDownload: 'exampleXlsxDownload',
      stSaveBulkImport: 'saveBulkImport',

      resetFilters: 'resetFilters',
      setCurrentPage: 'setCurrentPage',
      setPageSize: 'setPageSize',

      stCleanState: 'cleanState',
    }),

    async getData() {
      try {
        this.fetching = true
        await new Promise(resolve => setTimeout(() => resolve(), 300))
        await this.stFilterDeposit()

        if (this.$refs.tableForm) this.$refs.tableForm.showErrors()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    async resetFiltersLocal() {
      try {
        this.fetching = true
        await new Promise(resolve => setTimeout(() => resolve(), 300))
        await this.resetFilters()

        if (this.$refs.tableForm) this.$refs.tableForm.showErrors()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    async onSaveImport() {
      try {
        this.saving = true

        if (!this.isValidToSave) {
          this.showInvalidDataMessage({
            message: this.$tc(
              'BANK_DEPOSIT.UI.PAGE_XLSX_IMPORT_SAVE.HAS_INVALID_REGISTERS',
              this.getItemsSummary.totalErrors,
              {
                totalErrors: this.getItemsSummary.totalErrors,
              }
            ),
          })
          return
        }

        await this.stSaveBulkImport()

        this.showSuccess({ message: this.$t('Dados da importação salvos com sucesso.') })
        this.stCleanState()
        this.$router.back()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
    onCancelImport() {
      this.stCleanState()
      this.$router.back()
    },

    async sendFile() {
      if (!this.xlsxImportedFile?.length) return

      try {
        this.uploading = true
        await this.stSendXlsx({ file: this.xlsxImportedFile[0], storeId: this.store })
        await this.getData()
      } catch (error) {
        this.showError({
          error,
          message: this.$t('Não foi possível fazer a leitura da Planilha'),
        })
        this.stCleanState()
      } finally {
        this.uploading = false
      }
    },
    async removeFiles() {
      this.stCleanState()
      this.xlsxImportedFile = []
    },

    // TODO código para quando implementar a importação com modelo da echope
    // async onDownloadXlsxExample() {
    //   try {
    //     this.loadingExampleDownload = true

    //     const blobData = await this.stExampleXlsxDownload()

    //     if (blobData) {
    //       this.forceFileDownloadXlsx(
    //         blobData,
    //         this.$t('exemplo_importacao_deposito_conta_cofre.xlsx')
    //       )
    //       this.showSuccess({ message: this.$t('Sucesso.') })
    //     } else {
    //       this.showError('Ocorreu um erro ao realizar o download do exemplo de importação.')
    //     }
    //   } catch (error) {
    //     this.showError({ error })
    //   } finally {
    //     this.loadingExampleDownload = false
    //   }
    // },

    // async uploadFile() {
    //   let resultFile = null
    //   const result = await this.upload({
    //     files: [...this.xlsxImportedFile],
    //     destinationPath: 'images/stores',
    //   })

    //   if (this.xlsxImportedFile.length) {
    //     ;[resultFile] = result
    //   }

    //   const nullResult = { url: null }
    //   return [resultFile || nullResult]
    // },
  },
}
</script>

<style></style>
