<template>
  <div>
    <b-row class="mb-1">
      <b-col md="4">
        <FormulateInput
          :id="`prosegur_xlsx-period-${index}`"
          v-model="item.xlsxData.period"
          :label="$t('Período')"
          type="label"
          :errors="item.xlsxErrors['Period'] ? item.xlsxErrors['Period'] : []"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          :id="`prosegur_xlsx-company_name-${index}`"
          v-model="item.xlsxData.companyName"
          :label="$t('Razão Social')"
          type="label"
          :errors="item.xlsxErrors['CompanyName'] ? item.xlsxErrors['CompanyName'] : []"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          :id="`prosegur_xlsx-device_id-${index}`"
          v-model="item.xlsxData.deviceId"
          :label="$t('Device Id')"
          type="label"
          :errors="item.xlsxErrors['DeviceId'] ? item.xlsxErrors['DeviceId'] : []"
        />
      </b-col>
      <b-col md="4">
        <FormulateInput
          :id="`prosegur_xlsx-point-${index}`"
          v-model="item.xlsxData.point"
          :label="$t('Ponto')"
          type="label"
          :errors="item.xlsxErrors['Point'] ? item.xlsxErrors['Point'] : []"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2">
        <FormulateInput
          :id="`prosegur_xlsx-bank_number-${index}`"
          v-model="item.xlsxData.bankNumber"
          :label="$t('Banco')"
          type="label"
          :errors="item.xlsxErrors['BankNumber'] ? item.xlsxErrors['BankNumber'] : []"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          :id="`prosegur_xlsx-bank_branch_number-${index}`"
          v-model="item.xlsxData.bankBranchNumber"
          :label="$t('Agência')"
          type="label"
          :errors="item.xlsxErrors['BankBranchNumber'] ? item.xlsxErrors['BankBranchNumber'] : []"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          :id="`prosegur_xlsx-bank_account_number-${index}`"
          v-model="item.xlsxData.bankAccountNumber"
          :label="$t('Conta')"
          type="label"
          :errors="item.xlsxErrors['BankAccountNumber'] ? item.xlsxErrors['BankAccountNumber'] : []"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          :id="`prosegur_xlsx-value-${index}`"
          v-model="item.xlsxData.value"
          :label="$t('Valor a creditar')"
          type="label"
          filter="currency"
          :errors="item.xlsxErrors['Value'] ? item.xlsxErrors['Value'] : []"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: [String, Number],
      default: '0',
    },
  },
}
</script>

<style></style>
