<template>
  <div>
    <b-table
      ref="deposit-bulk-table"
      show-empty
      striped
      small
      responsive
      :class="{
        bordered: true,
        'mb-1': true,
      }"
      :tbody-tr-class="rowClass"
      :fields="fields"
      :items="items"
      :empty-text="getEmptyTableMessage($tc('BANK_DEPOSIT.UI.PAGE_XLSX_IMPORT_SAVE.NAME'), 'male')"
      :current-page="paging.currentPage"
      :per-page="paging.pageSize"
    >
      <template #cell(actions)="{ item }">
        <e-grid-actions
          :show-update="false"
          :show-delete="false"
          :buttons="gridActions"
          @show-details="onShowDetails(item)"
        />
      </template>

      <template #cell(storeId)="{ item, index }">
        <e-store-combo
          :id="`bulk_table-store_id_${index}`"
          v-model="item.storeId"
          :name="`storeId_${index}`"
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          :show-label="false"
          append-to-body
          @input="() => onBlurField(item)"
        />
      </template>

      <template #cell(depositDate)="{ item, index }">
        <FormulateInput
          :id="`bulk_table-deposit_date_${index}`"
          v-model="item.depositDate"
          :name="`depositDate_${index}`"
          type="datepicker"
          :placeholder="$t('dd/MM/aaaa')"
          validation="required"
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          @input="() => onBlurField(item)"
        />
      </template>

      <template #cell(description)="{ item, index }">
        <FormulateInput
          :id="`bulk_table-description_${index}`"
          v-model="item.description"
          :name="`description_${index}`"
          type="textarea"
          validation="required"
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          @blur="() => onBlurField(item)"
        />
      </template>

      <template
        v-if="isEchopeModel"
        #cell(documentType)="{ item, index }"
      >
        <FormulateInput
          :id="`bulk_table-document_type_${index}`"
          v-model="item.documentType"
          :name="`documentType_${index}`"
          type="vue-select"
          :options="documentTypeOptions()"
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          validation="required"
          @input="() => onBlurField(item)"
        />
      </template>

      <template #cell(documentNumber)="{ item, index }">
        <FormulateInput
          :id="`bulk_table-document_number_${index}`"
          v-model="item.documentNumber"
          :name="`document_number_${index}`"
          type="text"
          :validation-messages="{
            required: $t('Obrigatório'),
          }"
          validation="required"
          @blur="() => onBlurField(item)"
        />
      </template>

      <template #cell(value)="{ item, index }">
        <FormulateInput
          :id="`bulk_table-value_${index}`"
          v-model="item.value"
          :name="`value_${index}`"
          type="text-number"
          currency="R$"
          :precision="2"
          :validation-messages="{
            required: $t('Obrigatório'),
            min: $t('Deve ser maior que 0.01'),
          }"
          validation="required|min:0.01"
          @blur="() => onBlurField(item)"
        />
      </template>

      <template #row-details="{ item, index }">
        <div
          style="margin-left: 150px"
          class="bg-light p-1 rounded"
        >
          <p class="h4">
            {{ $t('Dados importados da planilha') }}
          </p>

          <bank-deposit-prosegur-xlsx-inputs
            :item="item"
            :index="index"
          />

          <!-- TODO ter um echope-xlsx-inputs -->
        </div>
      </template>

      <template #custom-foot>
        <tr>
          <th
            colspan="6"
            class="text-right"
          >
            {{ `${$t('Correção pendente')}: ${summary.totalErrors}` }}
          </th>
          <th class="text-right">
            Total
          </th>
          <th class="text-center">
            {{ summary.totalItems | currency }}
          </th>
        </tr>
      </template>
    </b-table>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { BTable } from 'bootstrap-vue'
import { financialDomain, formulateValidation, translate, checkingAccountDomains } from '@/mixins'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import BankDepositProsegurXlsxInputs from './BankDepositProsegurXlsxInputs.vue'

export default {
  components: {
    BTable,
    EStoreCombo,
    EGridActions,
    BankDepositProsegurXlsxInputs,
  },

  mixins: [formulateValidation, translate, financialDomain],

  props: {
    modelImport: {
      type: String,
      required: true,
      validation(value) {
        const { ECHOPE, PROSEGUR } = checkingAccountDomains.data().bankDepositImportModelEnum
        return [ECHOPE, PROSEGUR].includes(value)
      },
    },
    items: {
      type: Array,
      required: true,
    },
    paging: {
      type: Object,
      required: true,
    },
    summary: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    isEchopeModel() {
      return this.modelImport === checkingAccountDomains.data().bankDepositImportModelEnum.ECHOPE
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'actions',
          thStyle: { minWidth: '100px', width: '100px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('#'),
          key: 'xlsxData.line',
          thStyle: { minWidth: '50px', width: '50px' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: this.$t('Loja'),
          key: 'storeId',
          thStyle: { minWidth: '250px', width: '250px' },
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Dt. depósito'),
          key: 'depositDate',
          thStyle: { minWidth: '150px', width: '150px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Descrição'),
          key: 'description',
          // thStyle: { minWidth: '300px', width: '300px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Tipo documento'),
          key: 'documentType',
          thStyle: { minWidth: '110px', width: '110px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Nº Documento'),
          key: 'documentNumber',
          thStyle: { minWidth: '150px', width: '150px' },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { minWidth: '110px', width: '110px' },
          formatter: val => this.$options.filters.currency(val),
          sortable: true,
        },
      ]
    },

    gridActions() {
      return [
        {
          icon: 'journal-text',
          variant: 'primary',
          title: this.$t('Ver dados da importação'),
          event: 'show-details',
        },
      ].filter(btn => !btn.hide)
    },
  },

  mounted() {},

  methods: {
    // eslint-disable-next-line func-names
    onBlurField: debounce(function (item) {
      /* eslint-disable no-param-reassign */
      item.hasErrors = this.hasRowErrors(item)
      item.recordType = this.recordtypeEnum.MANUAL
      /* eslint-disable no-param-reassign */
    }, 500),
    hasRowErrors(item) {
      return (
        item.storeId == null || item.value == null || item.value <= 0 || item.documentNumber == null
      )
    },

    onShowDetails(item) {
      // eslint-disable-next-line no-underscore-dangle, no-param-reassign
      item._showDetails = !item._showDetails
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return ''
      return item?.hasErrors ? 'table-danger' : ''
    },
  },
}
</script>

<style lang="scss"></style>
